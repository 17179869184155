.Container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.ContainerInner {
    width: 100%;
    max-width: 1200px;
    padding: 80px 30px;
    box-sizing: border-box;
}

.Title {
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 30px;
}

.Accent {
    color: #4151E0;
}

.Content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.Quote {
    width: 55%;
    padding: 30px; box-sizing: border-box;
    text-align: left;
}

.QuoteContent {
    font-size: 30px;
}

.QuoteAuthor {
    margin-top: 20px;
    font-size: 20px;
}

.Image {
    width: 45%;
}

.Image img {
    width: 100%;
}

@media (max-width: 800px) {
    .ContainerInner {
        padding: 80px 20px;
    }

    .Quote {
        width: 100%;
        padding: 0;
    }

    .QuoteContent {
        font-size: 25px;
    }

    .Image {
        display: none;
    }
}
