.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: white;
}

.ContainerInner {
    width: 100%;
    max-width: 1200px;
    padding: 100px 20px;
    box-sizing: border-box;
}

.Title {
    color: #4151E0;
    font-size: 30px;
    font-weight: 800;
}

.Subtitle {
    font-size: 46px;
    font-weight: 500;
    margin: 40px;
}

.Accent {
    color: #4151E0;
}

.ImageSections {
    display: flex;
    align-items: center;
}

.Sections {
    display: flex;
    flex-wrap: wrap;
}

.Section {
    width: 33%;
    padding: 15px;
    box-sizing: border-box;
}

.Icon {
    width: 100%;
    margin-bottom: 20px;
    object-fit: contain;
}

.IconMobile {
    display: none;
}

.SectionTitle {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 15px;
    color: #4151E0;
}

.ButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.Button {
    width: fit-content;
    padding: 12px 70px;
    border-radius: 8px;
    background-color: #4151E0;
    font-weight: 600;
}

@media (max-width: 800px) {
    .Subtitle {
        font-size: 26px;
        font-weight: 500;
        margin: 40px;
    }

    .Section {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
    }

    .Icon {
        display: none;
    }

    .IconMobile {
        display: flex;
        width: 60%;
         margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        object-fit: contain;
    }
}
