.Container {
    position: relative;
    width: 100%;
}

.Video {
    width: 100%;
}

.GradientTop {
    position: absolute;
    top: 0;
    background: linear-gradient(to top, transparent, black);
    width: 100%;
    height: 100px;
}

.GradientBottom {
    position: absolute;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, black);
    width: 100%;
    height: 100px;
}

@media (max-width: 800px) {
    .GradientTop {
        height: 70px;
    }

    .GradientBottom {
        height: 70px;
    }
}
