.Container {
    position: relative;
}

.Input {
    padding: 15px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;
    background: none;
    font-size: inherit;
    border-radius: 3px;
}

.Input::placeholder {
    color: #767676;
}

.Error {
    color: #D93025;
    font-size: 13px;
    margin-bottom: 5px;
    width: 100%;
    text-align: left;
    margin-top: 5px;
}

.Error i {
    margin-right: 3px;
}

.InputError {
    outline: 2px solid #D93025 !important;
    border: none;
}
