.Container {
    width: 100vw;
    min-height: 60vh;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ContainerInner {
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    box-sizing: border-box;
}

.Title {
    font-size: 50px;
    padding: 60px 0;
    font-weight: 700;
}

.Accent {
    color: #4151E0;
}

.Tripods {
    width: 100%;
    display: flex;
    justify-content: center;
}


.Tripod {
    height: 300px;
    margin: 0 40px;
    max-width: 100%;
    object-fit: contain;
}


@media (max-width: 800px) {
    .Title {
        font-size: 35px;
        padding: 60px 0 0 0;
    }

}
