.Container {
    width: 100%;
    display: flex;
}

.ContainerInner {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}


.LogoContainer {
    max-width: 1400px;
    padding: 60px 60px 20px 60px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    z-index: 100;
    justify-content: space-between;
    margin-bottom: 50px;
}

.NavigationOptions {
    display: flex;
    align-items: center;
}

.NavigationOption {
    padding: 10px 20px;
    font-weight: 600;
    cursor: pointer;
}

.Logo {
    height: 40px;
    cursor: pointer;
}

.Content {
    max-width: 800px;
    padding: 20px;
    box-sizing: border-box;
}

.Title {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 40px;
}

.SectionTitle {
    font-weight: 600;
    font-size: 20px;
    margin: 20px 0;
    text-align: left;
}

.Section {
    text-align: left;
    margin-bottom: 20px;

}



@media (max-width: 800px) {
    .LogoContainer {
        padding: 20px;
    }

    .Logo {
        height: 30px;
    }
}
