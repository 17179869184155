.Container {
    min-height: 85vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background-color: #F5F5F7;*/
}

.ContainerInner {
    max-width: 1200px;
    width: 100%;
    padding: 40px 20px;
    box-sizing: border-box;
    text-align: left;
    display: flex;
}

.Gimbal {
    width: 35%;
    padding-right: 50px;
    box-sizing: border-box;
}

.Gimbal img {
    width: 100%;
}

.Content {
    width: 65%;
}

.Header {
    font-size: 20px;
    font-weight: 500;
    border-bottom: 2px solid #4151E0;
    display: inline-block;
    margin-bottom: 20px;
}

.Title {
    font-size: 50px;
    margin-bottom: 40px;
    font-weight: 700;
}

.Accent {
    color: #4151E0;
}

.Section {
    margin-bottom: 15px;
    line-height: 1.5;
}

.Form {
    width: 100%;
    display: flex;
    justify-content: center;
}

.TextInput {
    width: 60%;
    height: 48px;
    border: 1px solid black;
    box-sizing: border-box;
}

.SubmitButton {
    width: calc(40% - 15px);
    margin-left: 15px;
    height: 48px;
    font-weight: 600;
}

@media (max-width: 800px) {
    .Gimbal {
        display: none;
    }

    .Content {
        width: 100%;
    }

    .TextInput {
        width: 50%;
        height: 48px;
    }

    .Title {
        font-size: 35px;
    }

    .SubmitButton {
        width: calc(50% - 15px);
        margin-left: 15px;
        height: 48px;
        font-weight: 600;
    }
}
