.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #F5F5F7;
}

.ContainerInner {
    width: 100%;
    max-width: 1200px;
    padding: 100px 20px;
    box-sizing: border-box;
}

.Title {
    color: #4151E0;
    font-size: 30px;
    font-weight: 800;
}

.Subtitle {
    font-size: 46px;
    font-weight: 500;
    margin: 40px;
}

.Accent {
    color: #4151E0;
}

.Sections {
    display: flex;
    flex-wrap: wrap;
}

.Section {
    width: 33%;
    padding: 15px;
    box-sizing: border-box;
}

.Icon {
    width: 70px;
    margin-bottom: 20px;
    object-fit: contain;
}

.SectionTitle {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 15px;
}

.SectionSubtitle {

}

.ButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.Button {
    width: fit-content;
    padding: 12px 70px;
    border-radius: 8px;
    background-color: #4151E0;
    font-weight: 600;
}

@media (max-width: 800px) {
    .Subtitle {
        font-size: 26px;
        font-weight: 500;
        margin: 40px;
    }

    .Section {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
    }
}
