.Container {
    width: 100%;
    background-color: black;
    color: white;
    padding: 50px;
    box-sizing: border-box;
}

.Logo {
    height: 30px;
    margin-bottom: 40px;
}

.Title {
    font-size: 40px;
    font-weight: 700;
}

.Subtitle {
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    box-sizing: border-box;
}

.SubmitButton {
    color: black;
    background-color: white;
    margin: 20px 0 15px 0;
    height: 48px;
    font-weight: 600;
}

.CancelButton {
    border: 1px solid white;
    height: 48px;
    font-weight: 600;
}

@media (max-width: 800px) {
    .Container {
        padding: 20px;
    }
}
