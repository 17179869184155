.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: white;
}

.ContainerInner {
    width: 100%;
    max-width: 1200px;
    padding: 80px 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.Accent {
    color: #4151E0;
}

.Content {
    width: 45%;
    padding: 30px; box-sizing: border-box;
    text-align: left;
}

.Header {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 30px;
}

.Logo {
    width: 30px;
    margin-right: 10px;
}

.Image {
    width: 55%;
}

.ImageMobile {
    display: none;
}

.Image img {
    width: 100%;
}

.Title {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 20px;
}

.Section {
    margin-bottom: 10px;
}

.ButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.Button {
    margin-top: 50px;
    width: fit-content;
    padding: 12px 70px;
    border-radius: 8px;
    background-color: #4151E0;
    font-weight: 600;
}

@media (max-width: 800px) {
    .ContainerInner {
        padding: 80px 20px;
    }

    .Content {
        width: 100%;
        padding: 0;
    }

    .QuoteContent {
        font-size: 25px;
    }

    .Image {
        display: none;
    }

    .ImageMobile {
        display: flex;
        margin-bottom: 30px;
        justify-content: center;
    }

    .ImageMobile img {
        width: 60%;
    }

    .Header {
        margin-bottom: 10px;
    }
}
