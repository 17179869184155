.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.ContainerInner {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
}

.Preview {
    width: 55%;
}

.Preview img {
    width: 100%;
}

.Title {
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: 700;
}

.List {
    width: 100%;
    text-align: left;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.Form {
    padding-left: 40px;
    box-sizing: border-box;
    width: 45%;
}

.FormFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.Lock {
    height: 20px;
    margin-right: 5px;
    object-fit: contain;
}

.Stripe {
    height: 20px;
    margin-left: 5px;
    object-fit: contain;
}


@media (max-width: 800px) {
    .Preview {
        width: 100%;
        margin: 40px 0;
    }

    .Form {
        padding-left: 0;
        width: 100%;
        margin-bottom: 40px;
    }
}
