.Container {
    background-color: white;
    padding: 40px;
    box-sizing: border-box;
    color: black;
    max-width: 500px;
}

.CheckContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Check {
    background-color: black;
    color: white;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Check img {
    width: 70px;
    color: white;
}

.Title {
    font-size: 30px;
    font-weight: 600;
    margin: 20px 0;
}

.Content {
    margin-bottom: 20px;
}
