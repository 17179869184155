.Container {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    color: black;
    box-sizing: border-box;
    max-width: 600px;
}

.ContainerDark {
    background-color: black;
    color: white;
}

.Header {
    width: 100%;
    padding: 30px 30px 15px 30px;
    box-sizing: border-box;
    text-align: center;
}

.Title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 15px;
}

.Content {
    padding: 25px;
    box-sizing: border-box;
    width: 100%;
}

.TextInput {
    height: 48px;
    border-radius: 8px;
    background-color: #F6F6F6 !important;
}

.TextInputDark {
    background-color: #1F1F1F !important;
    color: white;
}

.SubmitButton {
    height: 48px;
    border-radius: 8px;
    font-weight: 600;
    text-align: center;
    margin-top: 15px;
}

.SubmitButtonDark {
    background-color: white !important;
    color: black !important;
}

@media (max-width: 800px) {
    .Container {
        padding: 20px;
    }

    .Form {
        margin: 20px 0;
    }
}
